<template>
  <footer>
    <div class="foot-nav-container pc_padding">
      <div class="footer-about">
        <div class="footer-item-title">
          About us
        </div>
        <div class="footer-item-content">
          MoviePulse.one is a hub for film lovers. Stay updated with the latest movie news, reviews, and suggestions, providing rich viewing options for every genre preference.
        </div>
      </div>
      <div class="footer-side-row">
        <div class="footer-side-resource">
          <div class="footer-item-title">
            Resources
          </div>
          <div class="footer-item-content resource">
            <div class="footer-item-content-item cursor" @click="goto('terms')">
              Terms of services
            </div>
            <div class="footer-item-content-item cursor" @click="goto('privacy')">
              Privacy policy
            </div>
            <div class="footer-item-content-item cursor" @click="goto('disclaimer')">
              Disclaimer
            </div>
          </div>
        </div>
        <div class="footer-side-contact">
          <div class="footer-item-title">
            Contact us
          </div>
          <div class="footer-item-content">
            <a class="footer-item-content-item"
              href="mailto:moviepulse.support@gmail.com">moviepulse.support@gmail.com</a>
          </div>
        </div>
      </div>
    </div>
    <div class="corner-module pc_padding">
      Copyright © 2024 MoviePulse. All Rights Reserved.
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FootModule1',
  computed: {
    ...mapGetters({
      host: 'host',
      deviceType: 'deviceType',
    }),
  },
  methods: {
    goto (where) {
      console.log(this.$route.path);
      this.$router.push({ name: where });
    },
  },
};
</script>
